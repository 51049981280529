import React, { useState, useEffect } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { CircularProgress, Step, StepLabel, Stepper, Button, Box, ImageList, ImageListItem, Modal, Dialog } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SnackbarProvider, useSnackbar } from "notistack";


declare global {
	interface NodeRequire {
		context: (
			directory: string,
			useSubdirectories: boolean,
			regExp: RegExp,
		) => {
			keys: () => string[];
			(id: string): { default: string };
		};
	}
}

interface FileQueueEntry {
	name: string;
	type: string;
	size: number;
	done: boolean;
	inProgress: boolean;
	src?: string;
	thumb_src?: string;
}

function App() {
	const { enqueueSnackbar } = useSnackbar();

	const [activeStep, setActiveStep] = React.useState(0);
	const [filesQueue, setFilesQueue]: [FileQueueEntry[], Function] = useState([]);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [imagesList, setImagesList]: [string[], Function] = useState([]);
	const [viewImage, setViewImage]: [string | null, Function] = useState(null);

	useEffect(() => {
		getThumbsList()
	}, [])


	function openImage(src: string) {
		setViewImage(`https://api.bymarto.com/uploads/${src}`)
	}

	async function getThumbsList() {
		await axios
			.get("https://api.bymarto.com/get_thumbs_list").then(res => {
				setImagesList(res.data)
			})
	}

	async function uploadFiles(formData: any[] | FormData) {
		enqueueSnackbar("Starting :)");

		const entries: IterableIterator<[string, FormDataEntryValue]> = formData.entries() as IterableIterator<
			[string, FormDataEntryValue]
		>;
		for (const [key, value] of entries) {
			if (value instanceof File) {
				try {
					const fileFormData = new FormData();
					fileFormData.append(key, value);
					await axios
						.post("https://api.bymarto.com/upload", fileFormData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
							onUploadProgress: (progressEvent: AxiosProgressEvent) => {
								const total = progressEvent?.total || 1;
								const loaded = progressEvent.loaded || 1;
								const percentCompleted = loaded / total;
								setUploadProgress(Math.round(percentCompleted * 100) - 1);
							},
						})
						.then(function (res) {

							setImagesList((oldList: string[]) => {
								return [...oldList, res.data.thumb_src]
							})
							setUploadProgress((_) => 0);
							setFilesQueue((oldFiles: FileQueueEntry[]) => {
								const idx = oldFiles.findIndex((file) => file.name === value.name);

								oldFiles[idx].done = true;
								oldFiles[idx].src = res.data.image_src;
								oldFiles[idx].thumb_src = res.data.thumb_src;
								if (oldFiles[idx + 1]) {
									oldFiles[idx + 1].inProgress = true;
								}
								oldFiles[idx].inProgress = false;

								return oldFiles.filter((file: FileQueueEntry, idx: number) => {
									return file.done !== true || idx > 2 && file.done === true
								});
							});
						});
				} catch (error) {
					console.error(`Error uploading ${key}:`, error);
				}

				enqueueSnackbar(`Successfully uploaded ${value.name}`);
				setActiveStep((old) => old + 1);
			}
		}
	}

	function handleSelectFiles(event: any) {
		const files = event?.target?.files;
		const queue = [];
		const fData = new FormData();
		for (let i = 0; i < files.length; i++) {
			queue.push({ name: files[i].name, type: files[i].type, size: files[i].size, done: false });
			fData.append("files[]", files[i]);
		}
		setFilesQueue(queue);
		uploadFiles(fData);
	}

	return (
		<Box flexDirection="row">
			<header className="App-header">
				<h1>Uplad your pics here!</h1>

				<form id="" encType="multipart/form-data">
					<Box gap={12} flexDirection={"row"} width={"100%"}>
						<Button
							component="label"
							sx={{ marginRight: 1 }}
							role={undefined}
							variant="contained"
							tabIndex={-1}
							startIcon={<CloudUploadIcon />}
						>
							Upload files
							<input
								type="file"
								onChange={handleSelectFiles}
								id="fileInput"
								name="files[]"
								multiple
								accept="image/*,video/*"
								style={{
									clip: "rect(0 0 0 0)",
									clipPath: "inset(50%)",
									height: 1,
									overflow: "hidden",
									position: "absolute",
									bottom: 0,
									left: 0,
									whiteSpace: "nowrap",
									width: 1,
								}}
							/>
						</Button>
					</Box>
				</form>
				<Box flexDirection={'column'} component="section">
					<ImageList sx={{ width: 500, height: 450 }} variant="quilted" cols={4} rowHeight={121}>
						{imagesList.map((image, index) => {
							console.log('imagesList', image);

							return (
								<ImageListItem key={image} cols={1} rows={1}>
									<img src={`/uploads/thumbs/${image}`} alt={image} loading="lazy" onClick={() => openImage(image)} />
								</ImageListItem>
							);
						})}
					</ImageList>
					{viewImage && <img style={{ maxWidth: "50vw" }} src={viewImage} />}
					{filesQueue && (
						<Stepper orientation="vertical" activeStep={activeStep}>
							{filesQueue.map((file, idx) => {
								return (
									<Step key={file.name}>
										<StepLabel
											icon={
												file.inProgress ? (
													<CircularProgress size={24} variant="determinate" value={uploadProgress} />
												) : (
													idx
												)
											}
											sx={{ color: "white" }}
										>
											{file.name}
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					)}
				</Box>
			</header>

			{/* <Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress variant="determinate" />
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="caption" component="div" color="text.secondary">
						{`${uploadProgress}%`}
					</Typography>
				</Box>
			</Box> */}
			{/* {filesQueue && (
				<div
					style={{ display: "flex", gap: 24, flexDirection: "column", justifyContent: "center", alignItems: "center" }}
				>
					<Box gap={12}>
						<CircularProgress
							variant="determinate"
							sx={{ padding: 10, color: "success.main" }}
							value={uploadProgress}
						/>
						{!!uploadProgress && <h1>{uploadProgress}%</h1>}

						<List dense sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
							{filesQueue.map((file, idx) => {
								return (
									<ListItem key={file.name}>
										<ListItemAvatar>
											<Avatar>
												<ImageIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary={file.name} secondary={file.size} />
									</ListItem>
								);
							})}
						</List>
					</Box>
				</div>
			)} */}
		</Box >
	);
}

export default App;
